<template>

<div class="main" :class="sectionsClass">
	<div class="columns columns-2">
		<div class="column">
			<h1>Contact Us</h1>
			<div v-if="!hideEmailAddress" class="contact-section contact-email">
				<h3><i class="fas fa-envelope"></i> Email Address</h3>
				<a :href="'mailto:' + client_contact.email_address">{{ client_contact.email_address }}</a>
			</div>

			<div class="contact-section contact-address">
				<h3><i class="fas fa-phone"></i> Store Contact Info</h3>
				<p>
					{{ client_contact.company_name }}<br>
					{{ client_contact.address_1 }}<br>
					<template v-if="client_contact.address_2">{{ client_contact.address_2 }}</template>
					{{ client_contact.city }}, {{ client_contact.state }} {{ client_contact.zip_code }}
				</p>
				<p>Phone: {{ client_contact.phone_number }}</p>
			</div>

			<div class="contact-section contact-hours">
				<h3><i class="fas fa-clock"></i> Store Hours</h3>
				<p v-html="store_hours"></p>
			</div>
		</div>
		<div class="column contact-form">
			<h3>Contact Form</h3>
			<label for="contact-name">Name</label>
			<input type="text" id="contact-name" class="required" v-model="contactForm.full_name">
			<label for="contact-email">Email Address</label>
			<input type="text" id="contact-email" class="required" v-model="contactForm.email_address">
			<label for="contact-phone">Phone Number</label>
			<input type="text" id="contact-phone" class="required" v-model="contactForm.phone_number">
			<label for="contact-city">City</label>
			<input type="text" id="contact-city" class="required" v-model="contactForm.city">
			<label for="contact-state">State</label>
			<input type="text" id="contact-state" class="required" v-model="contactForm.state">
			<label for="contact-request">Comment / Request</label>
			<textarea class="required" id="contact-request" v-model="contactForm.form_content"></textarea>
			<div :class="['button', contactSent ? 'disabled' : 'action']" @click="sendContact()" style="margin: 0.5em 0;"><span class="fas fa-spinner fa-spin" v-if="sendingContact"></span><span v-else>Send Contact</span></div>
			<div class="message">{{ sendContactMessage }}</div>

		</div>

		<div class="contact-map">
			<h3><i class="fas fa-map-marker-alt"></i> Store Map</h3>
			<GmapMap
			v-if="client_contact && client_contact.latitude && client_contact.longitude"
				:center="{ lat: parseFloat(client_contact.latitude), lng: parseFloat(client_contact.longitude) }"
				:zoom="7"
				map-type-id="terrain"
				:options="{
					scrollwheel: false,
					zoom: 14,
					gestureHandling: 'auto'
				}"
				style="width: 313px; height: 272px">
				<GmapMarker
					:key="index"
					v-for="(m, index) in markers"
					:position="m.position"
					:clickable="true"
					:draggable="true"
					@click="center = m.position" />
			</GmapMap>
			<a :href="'https://maps.google.com/?q=' + client_contact.latitude + ',' + client_contact.longitude" target="_blank" style="display: block; margin: 0.5em 0;">Directions to {{ client_contact.company_name }}</a>
		</div>

		<div v-if="!hideSubscribe" class="contact-signup">
			<h3>Subscribe to Our Mailing List</h3>
			<p>May we send you updates from time to time? Subscribe here...</p>
			<label for="subscribe-name">Name</label>
			<input type="text" id="subscribe-name" class="required" v-model="subscribeForm.full_name">
			<label for="subscribe-email">Email Address</label>
			<input type="text" id="subscribe-email" class="required" v-model="subscribeForm.email_address">
			<p v-if="mailchimpExtraText">{{ mailchimpExtraText }}</p>
			<div :class="['button', subscriptionSent ? 'disabled' : 'action']" @click="sendSubscription()" style="margin: 0.5em 0;"><span class="fas fa-spinner fa-spin" v-if="sendingSubscription"></span><span v-else>Subscribe</span></div>
			<div class="message">{{ sendSubscriptionMessage }}</div>
		</div>
	</div>

</div>

</template>

<script>
export default {
	name: 'contact',
	data() {
		return {
			client_contact: {},
			hideEmailAddress: false,
			hideSubscribe: false,
			markers: [],
			contactForm: {
				request: 'Contact Form',
				context: 'dealer'
			},
			sendContactMessage: '',
			sendingContact: false,
			contactSent: false,
			subscribeForm: {
				request: 'Subscription Request',
				context: 'dealer'
			},
			sendSubscriptionMessage: '',
			sendingSubscription: false,
			subscriptionSent: false,
			mailchimpSubscribeListId: false,
			mailchimpExtraText: '',
			mailchimpRequireName: false
		}
	},
	created() {
        let preferenceCodes = ['HIDE_EMAIL_ADDRESS', 'HIDE_SUBSCRIBE', 'MAILCHIMP_SUBSCRIBE_LIST_ID', 'MAILCHIMP_EXTRA_TEXT']
        this.apiCall({ destination: 'get_item_preference', data: { preference_code: preferenceCodes } }).then(obj => {
			this.hideEmailAddress = obj.HIDE_EMAIL_ADDRESS
			this.hideSubscribe = obj.HIDE_SUBSCRIBE
			this.mailchimpSubscribeListId = obj.MAILCHIMP_SUBSCRIBE_LIST_ID
			this.mailchimpExtraText = obj.MAILCHIMP_EXTRA_TEXT
			this.fetchData()
        }).catch(err => {
            console.log(err)
        })
	},
	watch: {
		client_contact(newVal) {
			if (!this.client_contact.latitude) { return }
			if (newVal && newVal.latitude && newVal.longitude) {
				this.markers = [
					{
						position: { lat: parseFloat(newVal.latitude), lng: parseFloat(newVal.longitude) }
					}
				]
			}
		}
	},
	computed: {
		store_hours() {
			if (!this.client_contact.store_hours) return
			return this.client_contact.store_hours.replace(/\n/g, "<br>")
		},
		sectionsClass() {
			if (!this.getTemplateData.siteOptions) return

			return [
				(this.getTemplateData.siteOptions.fullWidth) ? 'full-width' : ''
			]
		}
	},
	methods: {
		fetchData() {
			this.apiCall({ destination: 'get_item_client_contact' }).then(obj => {
                this.client_contact = obj.client_contact
            }).catch(error => {
                console.log(error)
            })
		},
		sendContact() {
			if (this.sendingContact || this.contactSent) {
				return false
			}
			this.sendContactMessage = ''
			if (!this.contactForm.full_name || !this.contactForm.email_address || !this.contactForm.phone_number || !this.contactForm.form_content) {
				this.sendContactMessage = 'Oops! Name, email address, phone number and comment are required.'
				return false
			}
			if (!this.isValidEmailAddress(this.contactForm.email_address)) {
				this.sendContactMessage = 'Oops! Please enter a complete email address.'
				return false
			}
			if (!this.isValidPhoneNumber(this.contactForm.phone_number)) {
				this.sendContactMessage = 'Oops! Please enter a complete phone number.'
				return false
			}
			this.sendingContact = true
			this.apiCall({ destination: 'send_contact_form', data: this.contactForm }).then(obj => {
				this.sendingContact = false
				this.sendContactMessage = obj.message
				if (obj.status == 'success') {
					this.contactSent = true
				}
            }).catch(error => {
                console.log(error)
            })
		},
		sendSubscription() {
			if (this.sendingSubscription || this.subscriptionSent) {
				return false
			}
			this.sendSubscriptionMessage = ''
			if (!this.subscribeForm.full_name || !this.subscribeForm.email_address) {
				this.sendSubscriptionMessage = 'Oops! Name and email address are required.'
				return false
			}
			if (!this.isValidEmailAddress(this.subscribeForm.email_address)) {
				this.sendSubscriptionMessage = 'Oops! Please enter a valid email address.'
				return false
			}
			this.sendingSubscription = true
			let destination = 'send_contact_form'
			if (this.mailchimpSubscribeListId) {
				destination = 'add_mailchimp_subscriber'
			}
			this.apiCall({ destination: destination, data: this.subscribeForm }).then(obj => {
				this.sendingSubscription = false
				this.sendSubscriptionMessage = obj.message
				if (obj.status == 'success') {
					this.subscriptionSent = true
				}
			}).catch(error => {
				console.log(error)
			})
		},
		isValidEmailAddress(emailAddress) {
			var pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			return pattern.test(emailAddress);
		},
		isValidPhoneNumber(phoneNumber) {
			var pattern = new RegExp(/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/);
			return pattern.test(phoneNumber);
		}

	}
}
</script>

<style lang="scss" scoped>
.columns {
	grid-column-gap: 4rem;
	padding: 0 1em;
	align-items: start;
}
h1 {
	border-bottom: 1px dotted #949494;
}
.message {
	display: block;
	border: none;
	line-height: 2em;
    padding-left: 4px;
}
a {
	// color: #005796;
	color: inherit;
	text-decoration: underline;
}
.contact-section {
	border-bottom: 1px dotted #949494;
	padding-bottom: 1rem;
}

.contact-form, .contact-signup {
	display: grid;
	grid-auto-flow: row dense;
	input, textarea {
		font-size: 1em;
		padding: 0.5em;
		width: auto;
		margin-bottom: 0.25em;
		resize: none;
		/*border: none;*/
	}
	label {
		display: block;
	}
}
.contact-signup {
	grid-auto-rows: min-content;
	align-content: end;
}

</style>
